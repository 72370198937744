.letters-container {
  text-align: center;
  padding-top: 35px;
  padding-bottom: 30px;
}

.letters {
  margin: 0 auto;
  //   margin: -25px;

  @media screen and (min-width: 550px) {
    display: flex;
    flex-wrap: wrap;
  }
}

.letters__item {
  height: 250px;
  margin-bottom: 50px;

  @media screen and (min-width: 550px) {
    width: calc((100% - 4 * 25px) / 2);
    margin: 25px;
  }

  @media screen and (min-width: 850px) {
    width: calc((100% - 6 * 25px) / 3);
  }

  @media screen and (min-width: 1560px) {
    width: calc((100% - 10 * 25px) / 5);
  }

  @media screen and (min-width: 2070px) {
    width: calc((100% - 14 * 25px) / 7);
  }
}

.letters__item img {
  //   display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform $trans-dur $trans-time-func;
  // image-rendering: pixelated;

  &:hover {
    transform: scale($img-scale);
  }
}
