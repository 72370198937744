.backdrop {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  overflow-y: scroll;

  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);

  opacity: 1;
  visibility: visible;
  transition: opacity $trans-dur $trans-time-func, visibility $trans-dur $trans-time-func;
}

.backdrop--is-hidden {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.backdrop--is-hidden .backdrop__modal {
  transform: translate(-50%, -50%) scale(0.8);
}

.backdrop__modal {
  overflow-y: auto;
  position: absolute;
  z-index: 4;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);

  padding: 30px;
  text-align: center;
  text-transform: uppercase;
  background-color: #fff;
  color: $color-modal;
  font-family: 'Times New Roman', Times, serif;
  letter-spacing: 0.1em;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.2);
  border-radius: 2px;

  transition: transform $trans-dur $trans-time-func;

  width: calc(100% - 30px);
  max-width: 700px;
  max-height: calc(100vh - 30px);
}

.backdrop__modal-title {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 20px;

  //   @media screen and (max-width: $brk-point-desktop-) {
  //     line-height: (30px / 20px);
  //   }

  //   @media screen and (min-width: $brk-point-desktop) {
  //     line-height: (23px / 20px);
  //   }
}
