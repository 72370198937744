@import './utils/vars';
@import './utils/_normalize.scss';
@import './utils/base-styles';
@import './utils/visually-hidden';
@import './utils/common';

@import './components/header';
@import './components/section-hero';
@import './components/alphabet';
@import './components/letters';
@import './components/postcards';
// @import './components/paintings';
@import './components/email-form';
@import './components/modal';
