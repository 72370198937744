@import "https://fonts.googleapis.com/css2?family=Caveat:wght@500&display=swap";
*, :before, :after {
  box-sizing: border-box;
}

html {
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

hr {
  height: 0;
  color: inherit;
}

abbr[title] {
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

:-moz-ui-invalid {
  box-shadow: none;
}

legend {
  padding: 0;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

ul, h1, h2, h3, p {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

img {
  width: 100%;
  height: auto;
  display: block;
}

.container {
  margin: 0 auto;
}

@media screen and (max-width: 1049px) {
  .container {
    padding: 0 15px;
  }
}

@media screen and (min-width: 1050px) and (max-width: 1559px) {
  .container {
    max-width: 1020px;
  }
}

@media screen and (min-width: 1560px) and (max-width: 2069px) {
  .container {
    max-width: 1530px;
  }
}

@media screen and (min-width: 2070px) {
  .container {
    max-width: 2040px;
  }
}

body {
  background-image: url("white-waves.6ba85bb9.webp");
  background-attachment: fixed;
  overflow-y: scroll;
}

@media screen and (max-width: 767px) {
  body.is-open {
    overflow-y: hidden;
  }
}

.page-name {
  font-family: Caveat, cursive;
  font-size: 60px;
  font-weight: 500;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lock-scroll {
  overflow-y: hidden;
}

.visually-hidden {
  white-space: nowrap;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.section {
  padding: 15px 20px 20px;
}

.gallery {
  max-width: 2000px;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 340px));
  grid-auto-rows: min-content;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  padding: 40px 0 30px;
  list-style: none;
  transition: opacity .25s cubic-bezier(.4, 0, .2, 1);
  display: grid;
}

.gallery.hidden {
  opacity: 0;
}

.gallery__item {
  position: relative;
}

.gallery__image {
  max-height: 400px;
  height: 100%;
  width: 100%;
  object-fit: contain;
  transition: transform .25s cubic-bezier(.4, 0, .2, 1);
}

.gallery__image:hover {
  transform: scale(1.03);
}

.gallery__image--contain {
  object-fit: contain;
}

.gallery__link {
  height: 100%;
  text-decoration: none;
  display: block;
}

.gallery__link:hover {
  cursor: zoom-in;
}

.header-container {
  padding: 15px 15px 5px;
}

@media screen and (min-width: 1050px) {
  .header-container {
    padding: 15px 30px 5px;
  }
}

.mobile-nav {
  justify-content: space-between;
  display: flex;
}

@media screen and (min-width: 768px) {
  .mobile-nav {
    display: none;
  }
}

@media screen and (min-height: 540px) {
  .logo {
    z-index: 2;
  }
}

.logo__icon {
  width: auto;
  height: 70px;
}

.menu-button {
  width: 40px;
  height: 40px;
  z-index: 2;
  cursor: pointer;
  background-color: #0000;
  border: none;
  padding: 0;
}

.menu-button.is-open .menu-button__icon-menu, .menu-button__icon-cross {
  display: none;
}

.menu-button.is-open .menu-button__icon-cross {
  display: block;
}

@media screen and (max-width: 767px) {
  .menu-container {
    z-index: 1;
    width: 100vw;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    background-color: #fff;
    justify-content: space-between;
    align-items: flex-end;
    padding: 15px;
    transition: opacity .25s cubic-bezier(.4, 0, .2, 1), transform .25s cubic-bezier(.4, 0, .2, 1);
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
    transform: scale(.8);
  }

  .menu-container.is-open {
    opacity: 1;
    pointer-events: all;
    transform: scale(1);
  }

  .menu-container .logo {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .menu-container {
    flex-grow: 1;
    justify-content: space-between;
    display: flex;
    position: relative;
  }
}

@media screen and (min-width: 1000px) {
  .navigation {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.navigation__list {
  display: flex;
}

@media screen and (max-width: 767px) {
  .navigation__list {
    flex-direction: column;
  }
}

.navigation__item {
  text-align: center;
  transition: transform .25s cubic-bezier(.4, 0, .2, 1);
}

@media screen and (max-width: 767px) {
  .navigation__item:hover, .navigation__item:focus {
    transform: translateX(20px);
  }

  .navigation__item:not(:last-child) {
    margin-bottom: 30px;
  }

  .navigation__item.current {
    transform: translateX(20px);
  }
}

@media screen and (min-width: 768px) {
  .navigation__item:hover, .navigation__item:focus {
    transform: translateY(20px);
  }

  .navigation__item:not(:last-child) {
    margin-right: 30px;
  }

  .navigation__item.current {
    transform: translateY(20px);
  }
}

.navigation__link {
  display: inline-block;
}

.navigation__icon {
  width: auto;
  height: 70px;
}

@media screen and (min-width: 768px) {
  .soc-list {
    justify-content: space-between;
    display: flex;
  }
}

.soc-list__item:not(:last-child) {
  margin-right: 5px;
}

.soc-list__link {
  width: 50px;
  height: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.soc-list__link:hover .soc-list__icon {
  opacity: 1;
  fill: #a21e18;
}

.soc-list__icon {
  width: 35px;
  height: 35px;
  fill: #0000004d;
  transition: fill .25s cubic-bezier(.4, 0, .2, 1);
}

.hero-img-containet {
  text-align: center;
  height: calc(100vh - 130px);
  padding-top: 15px;
}

.hero-img {
  max-height: 100%;
  width: auto;
  cursor: pointer;
  transition: transform .25s cubic-bezier(.4, 0, .2, 1);
  display: inline-block;
}

.hero-img:hover {
  transform: scale(1.01);
}

.about__container {
  z-index: 4;
  color: #573838;
  letter-spacing: .1em;
  width: calc(100% - 30px);
  max-width: 900px;
  max-height: calc(100vh - 30px);
  background-color: #fff;
  border-radius: 2px;
  padding: 30px;
  font-family: Times New Roman, Times, serif;
  transition: transform .25s cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 50%;
  left: 50%;
  overflow-y: auto;
  transform: translate(-50%, -50%)scale(1);
  box-shadow: 0 1px 3px #0000001f, 0 1px 1px #00000024, 0 2px 1px #0003;
}

.about__text {
  margin-bottom: 15px;
}

.about__info__item:not(:last-child) {
  margin-bottom: 10px;
}

.abc-nav {
  padding: 30px 15px;
}

.abc-nav__list {
  max-width: 210px;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 0 auto;
  display: grid;
}

.abc-nav__item {
  cursor: pointer;
  transition: transform .25s cubic-bezier(.4, 0, .2, 1);
  box-shadow: 1.95px 1.95px 2.6px #00000026;
}

.abc-nav__item.item--hor {
  grid-column-start: span 2;
}

.abc-nav__item.item--vert {
  grid-rov-start: span 2;
}

.abc-nav__item:hover, .abc-nav__item:focus {
  transform: scale(1.02);
}

.abc-nav__item:active {
  transform: translate(2px, 2px);
}

.letters-container {
  text-align: center;
  padding-top: 35px;
  padding-bottom: 30px;
}

.letters {
  margin: 0 auto;
}

@media screen and (min-width: 550px) {
  .letters {
    flex-wrap: wrap;
    display: flex;
  }
}

.letters__item {
  height: 250px;
  margin-bottom: 50px;
}

@media screen and (min-width: 550px) {
  .letters__item {
    width: calc(50% - 50px);
    margin: 25px;
  }
}

@media screen and (min-width: 850px) {
  .letters__item {
    width: calc(33.3333% - 50px);
  }
}

@media screen and (min-width: 1560px) {
  .letters__item {
    width: calc(20% - 50px);
  }
}

@media screen and (min-width: 2070px) {
  .letters__item {
    width: calc(14.2857% - 50px);
  }
}

.letters__item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform .25s cubic-bezier(.4, 0, .2, 1);
}

.letters__item img:hover {
  transform: scale(1.2);
}

.post-nav {
  padding: 30px 15px;
}

.post-nav__list {
  max-width: 700px;
  grid-template-rows: repeat(3, min-content);
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  margin: 0 auto;
  display: grid;
}

.post-nav__item {
  cursor: pointer;
  transition: transform .25s cubic-bezier(.4, 0, .2, 1);
  box-shadow: 1.95px 1.95px 2.6px #00000026;
}

.post-nav__item.item--hor {
  grid-column-start: span 2;
}

.post-nav__item.item--vert {
  grid-rov-start: span 2;
}

.post-nav__item:hover, .post-nav__item:focus {
  transform: scale(1.02);
}

.post-nav__item:active {
  transform: translate(2px, 2px);
}

.contact__pers-data-container {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .contact__pers-data-container {
    display: flex;
  }
}

.contact__field {
  text-align: left;
  cursor: pointer;
  flex-direction: column;
  flex-grow: 1;
  margin-bottom: 10px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .contact__field:first-child {
    margin-right: 20px;
  }
}

.contact__field.contact__field--last {
  margin-bottom: 30px;
}

.contact__field-lable {
  padding-bottom: 4px;
  font-size: 10px;
  font-weight: 700;
  line-height: 1.17;
  display: block;
}

.contact__input {
  height: 40px;
  cursor: pointer;
  letter-spacing: .01em;
  border: 1px solid #573838;
  border-radius: 2px;
  outline: none;
  flex-grow: 1;
  padding: 0 12px;
  font-size: 16px;
  line-height: 1.14;
  transition: border-color .25s cubic-bezier(.4, 0, .2, 1);
}

.contact__input:focus {
  border-color: #a1200f;
}

.contact__input--textarea {
  height: 120px;
  resize: none;
  padding: 12px;
}

.contact-submit {
  cursor: pointer;
  color: #fff;
  text-transform: inherit;
  letter-spacing: inherit;
  background-color: #573838;
  border: none;
  margin: 0 auto;
  padding: 10px 15px;
  font-weight: 500;
  display: block;
}

.contact-submit:active {
  transform: translate(0, 1px);
}

.backdrop {
  z-index: 3;
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: visible;
  background-color: #0003;
  transition: opacity .25s cubic-bezier(.4, 0, .2, 1), visibility .25s cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: scroll;
}

.backdrop--is-hidden {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.backdrop--is-hidden .backdrop__modal {
  transform: translate(-50%, -50%)scale(.8);
}

.backdrop__modal {
  z-index: 4;
  text-align: center;
  text-transform: uppercase;
  color: #573838;
  letter-spacing: .1em;
  width: calc(100% - 30px);
  max-width: 700px;
  max-height: calc(100vh - 30px);
  background-color: #fff;
  border-radius: 2px;
  padding: 30px;
  font-family: Times New Roman, Times, serif;
  transition: transform .25s cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: 50%;
  left: 50%;
  overflow-y: auto;
  transform: translate(-50%, -50%)scale(1);
  box-shadow: 0 1px 3px #0000001f, 0 1px 1px #00000024, 0 2px 1px #0003;
}

.backdrop__modal-title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
}

body.hidden-scroll {
  overflow: hidden;
}

.sl-overlay {
  z-index: 1035;
  background: #fff;
  display: none;
  position: fixed;
  inset: 0;
}

.sl-wrapper {
  z-index: 1040;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.sl-wrapper * {
  box-sizing: border-box;
}

.sl-wrapper button {
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
  font-size: 28px;
}

.sl-wrapper button:hover {
  opacity: .7;
}

.sl-wrapper .sl-close {
  z-index: 10060;
  height: 44px;
  width: 44px;
  color: #000;
  margin-top: -14px;
  margin-right: -14px;
  font-family: Arial, Baskerville, monospace;
  font-size: 3rem;
  line-height: 44px;
  display: none;
  position: fixed;
  top: 30px;
  right: 30px;
}

.sl-wrapper .sl-counter {
  z-index: 1060;
  color: #000;
  font-size: 1rem;
  display: none;
  position: fixed;
  top: 30px;
  left: 30px;
}

.sl-wrapper .sl-navigation {
  width: 100%;
  display: none;
}

.sl-wrapper .sl-navigation button {
  height: 44px;
  width: 22px;
  text-align: center;
  z-index: 10060;
  color: #000;
  margin-top: -22px;
  font-family: Arial, Baskerville, monospace;
  line-height: 44px;
  display: block;
  position: fixed;
  top: 50%;
}

.sl-wrapper .sl-navigation button.sl-next {
  font-size: 2rem;
  right: 5px;
}

.sl-wrapper .sl-navigation button.sl-prev {
  font-size: 2rem;
  left: 5px;
}

@media (min-width: 35.5em) {
  .sl-wrapper .sl-navigation button {
    width: 44px;
  }

  .sl-wrapper .sl-navigation button.sl-next {
    font-size: 3rem;
    right: 10px;
  }

  .sl-wrapper .sl-navigation button.sl-prev {
    font-size: 3rem;
    left: 10px;
  }
}

@media (min-width: 50em) {
  .sl-wrapper .sl-navigation button {
    width: 44px;
  }

  .sl-wrapper .sl-navigation button.sl-next {
    font-size: 3rem;
    right: 20px;
  }

  .sl-wrapper .sl-navigation button.sl-prev {
    font-size: 3rem;
    left: 20px;
  }
}

.sl-wrapper.sl-dir-rtl .sl-navigation {
  direction: ltr;
}

.sl-wrapper .sl-image {
  -ms-touch-action: none;
  touch-action: none;
  z-index: 10000;
  position: fixed;
}

.sl-wrapper .sl-image img {
  width: 100%;
  height: auto;
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
}

@media (min-width: 35.5em) {
  .sl-wrapper .sl-image img {
    border: 0;
  }
}

@media (min-width: 50em) {
  .sl-wrapper .sl-image img {
    border: 0;
  }
}

.sl-wrapper .sl-image iframe {
  background: #000;
  border: 0;
}

@media (min-width: 35.5em) {
  .sl-wrapper .sl-image iframe {
    border: 0;
  }
}

@media (min-width: 50em) {
  .sl-wrapper .sl-image iframe {
    border: 0;
  }
}

.sl-wrapper .sl-image .sl-caption {
  color: #fff;
  background: #000c;
  padding: 10px;
  font-size: 1rem;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.sl-wrapper .sl-image .sl-caption.pos-top {
  top: 0;
  bottom: auto;
}

.sl-wrapper .sl-image .sl-caption.pos-outside {
  bottom: auto;
}

.sl-wrapper .sl-image .sl-download {
  color: #000;
  z-index: 1060;
  display: none;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.sl-spinner {
  height: 40px;
  opacity: 0;
  width: 40px;
  z-index: 1007;
  -ms-animation: pulsate 1s ease-out infinite;
  border: 5px solid #333;
  border-radius: 40px;
  margin: -20px 0 0 -20px;
  animation: pulsate 1s ease-out infinite;
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
}

.sl-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

.sl-transition {
  transition: transform .2s;
}

@keyframes pulsate {
  0% {
    opacity: 0;
    transform: scale(.1);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

/*# sourceMappingURL=index.10d7aa1c.css.map */
