.post-nav {
  padding: 30px 15px;
}
.post-nav__list {
  max-width: 700px;
  margin: 0 auto;

  display: grid;
  gap: 10px;

  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(3, min-content);
}
.post-nav__item {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  cursor: pointer;

  transition: transform $trans-dur $trans-time-func;

  &.item--hor {
    grid-column-start: span 2;
  }

  &.item--vert {
    grid-rov-start: span 2;
  }

  &:hover,
  &:focus {
    transform: scale(1.02);
  }

  &:active {
    transform: translate(2px, 2px);
  }
}
