.section {
  padding: 15px 20px 20px;
}

.gallery {
  display: grid;
  max-width: 2000px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 340px));
  grid-auto-rows: min-content;
  grid-gap: 30px;
  justify-content: center;
  padding: 40px 0 30px;
  list-style: none;
  margin-left: auto;
  margin-right: auto;

  transition: opacity $trans-dur $trans-time-func;

  &.hidden {
    opacity: 0;
  }
}

.gallery__item {
  position: relative;
  // box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
  //   0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}

.gallery__image {
  max-height: 400px;
  height: 100%;
  width: 100%;
  object-fit: contain;
  transition: transform $trans-dur $trans-time-func;

  &:hover {
    transform: scale(1.03);
  }
}

.gallery__image--contain {
  object-fit: contain;
}

.gallery__link {
  display: block;
  text-decoration: none;
  height: 100%;

  &:hover {
    cursor: zoom-in;
  }
}
