.header-container {
  padding: 15px 15px 5px;

  @media screen and (min-width: 1050px) {
    padding: 15px 30px 5px;
  }
}

.mobile-nav {
  display: flex;
  justify-content: space-between;
  @media screen and (min-width: $brk-point-tablet) {
    display: none;
  }
}

.logo {
  @media screen and (min-height: 540px) {
    z-index: 2;
  }
}

.logo__icon {
  width: auto;
  height: 70px;
}

.menu-button {
  padding: 0;
  width: 40px;
  height: 40px;
  z-index: 2;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.menu-button.is-open .menu-button__icon-menu {
  display: none;
}

.menu-button__icon-cross {
  display: none;

  .menu-button.is-open & {
    display: block;
  }
}

.menu-container {
  @media screen and (max-width: $brk-point-tablet-) {
    position: fixed;
    z-index: 1;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background-color: #fff;
    opacity: 0;
    transform: scale(0.8);
    pointer-events: none;
    overflow-y: auto;

    transition: opacity $trans-dur $trans-time-func, transform $trans-dur $trans-time-func;

    &.is-open {
      opacity: 1;
      transform: scale(1);
      pointer-events: all;
    }

    & .logo {
      display: none;
    }
  }

  @media screen and (min-width: $brk-point-tablet) {
    position: relative;
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
  }
}

.navigation {
  @media screen and (min-width: 1000px) {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.navigation__list {
  display: flex;

  @media screen and (max-width: $brk-point-tablet-) {
    flex-direction: column;
  }
}

.navigation__item {
  transition: transform $trans-dur $trans-time-func;
  text-align: center;

  @media screen and (max-width: $brk-point-tablet-) {
    &:hover,
    &:focus {
      transform: translateX(20px);
    }

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    &.current {
      transform: translateX(20px);
    }
  }

  @media screen and (min-width: $brk-point-tablet) {
    &:hover,
    &:focus {
      transform: translateY(20px);
    }

    &:not(:last-child) {
      margin-right: 30px;
    }

    &.current {
      transform: translateY(20px);
    }
  }
}

.navigation__link {
  display: inline-block;
}

.navigation__icon {
  width: auto;
  height: 70px;
}

.soc-list {
  @media screen and (min-width: $brk-point-tablet) {
    display: flex;
    justify-content: space-between;
  }
}

.soc-list__item:not(:last-child) {
  margin-right: 5px;
}

.soc-list__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;

  &:hover .soc-list__icon {
    opacity: 1;
    fill: #a21e18;
  }
}

.soc-list__icon {
  width: 35px;
  height: 35px;
  fill: rgba(0, 0, 0, 0.3);

  transition: fill $trans-dur $trans-time-func;
}
