ul,
h1,
h2,
h3,
p {
  padding: 0;
  margin: 0;
}

ul {
  list-style-type: none;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

.container {
  margin: 0 auto;

  @media screen and (max-width: 1049px) {
    padding: 0 15px;
  }

  @media screen and (min-width: 1050px) and (max-width: 1559px) {
    max-width: 1020px;
  }

  @media screen and (min-width: 1560px) and (max-width: 2069px) {
    max-width: 1530px;
  }

  @media screen and (min-width: 2070px) {
    max-width: 2040px;
  }
}

body {
  background-image: url('../images/white-waves.webp');
  background-attachment: fixed;

  overflow-y: scroll;

  @media screen and (max-width: $brk-point-tablet-) {
    &.is-open {
      overflow-y: hidden;
    }
  }
}

@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@500&display=swap');

.page-name {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-family: 'Caveat', cursive;
  font-weight: 500;
  font-size: 60px;
}

.lock-scroll {
  overflow-y: hidden;
}
